














import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "settings-navigation",
  data() {
    return {
      links: [
        /* {
          label: "Types of Appointments",
          href: "/nomenclators/appointment_types",
        },
        {
          label: "Subtypes of Procedures",
          href: "/nomenclators/procedures-subtypes",
        },
        {
          label: "Procedures status",
          href: "/nomenclators/procedure_status",
        }, */
        {
          label: "Leads status",
          href: "/nomenclators/lead_status",
        },
        /*  {
          label: "Clients status",
          href: "/nomenclators/client_status",
        }, */
        /* {
          label: "Form Procedures",
          href: "/nomenclators/procedure_form",
        }, */
        /*   {
          label: "Medical locations",
          href: "/nomenclators/medical-locations",
        }, */
        {
          label: "Occupations",
          href: "/nomenclators/ocupations",
        },
        /*  {
          label: "Accesories",
          href: "/nomenclators/accessories",
        }, */
        {
          label: "Sources",
          href: "/nomenclators/sources_nom",
        },
        {
          label: "Tags",
          href: "/nomenclators/tags_nom",
        },

        {
          label: "Companies",
          href: "/nomenclators/companies_nom",
        },
        {
          label: "Documents",
          href: "/nomenclators/documents_nom",
        },
        {
          label: "State City",
          href: "/nomenclators/add_stateCity",
        },
      ],
    };
  },

  computed: {
    ...mapState(["dataCRM"]),
  },
});
